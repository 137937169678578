<template>
  <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
          <!-- <a href="#" @click.prevent="prev" class="fullcalendar-btn-prev btn btn-sm btn-default">
            <i class="fas fa-angle-left"></i>
          </a>
          <a href="#" @click.prevent="next" class="fullcalendar-btn-next btn btn-sm btn-default">
            <i class="fas fa-angle-right"></i>
          </a>
          <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'dayGridMonth'}"
                       @click="changeView('dayGridMonth')">
            Month
          </base-button>
          <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'dayGridWeek'}"
                       @click="changeView('dayGridWeek')">
            Week
          </base-button>
          <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'timeGridDay'}"
                       @click="changeView('timeGridDay')">
            Day
          </base-button> -->
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- Fullcalendar -->

          <add-bar @add="addTask"></add-bar>
          <div class="card">

            <!-- Card header -->
            <!-- <div class="card-header">
              <h5 class="h3 mb-0">Calendar</h5>
            </div> -->
            <!-- Card body -->
            <div class="card-body">
              <apa-comments
                :fixtures="fixture"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import NewsItem from './NewsItem'
  import Comment from '@/components/Feed/Comment'
  import SocialInput from '@/components/Feed/SocialInput'
  import ApaComments from "@/components/ApaComments/ApaComments";

  import { mapGetters } from 'vuex'

  export default {
    name: 'calendar',
    components: {
      NewsItem,
      SocialInput,
      Comment,
      ApaComments
    },
    computed: {
      ...mapGetters([
        'news'
      ]),
      openParentTasks () {
        return this.parentTasks
      },
      fixture() {
        if (!this.item) return {}
        return {
          id: this.item.id,
          project_id: this.item.project_id,
          position_id: this.item.position_id,
          pred_id: this.item.pred_id,
        }
      }
    },
    data() {
      return {
        showEdit: false,
        showDetail: false,
        currentTask: null,
        item: null
      }
    },
    methods: {
      addTask(task) {
        task.id = this.tasks.length + 1
        this.$store.dispatch('addTask', task)
      },
      onItemEdit (item) {
        this.showEdit = true
        this.currentTask = item
      },
      taskClicked (item) {
        this.showDetail = true
        this.currentTask = item
      }
    },
    async mounted () {
      const comments = await this.$store.dispatch('loadComments', {
        filter: {id: this.$route.params.id},
      });
      this.item = comments[0]
    }
  };
</script>
