<template>
  <div class="container-fluid">
    <base-header class="py-2">
       <div class="row align-items-center py-2">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">Projekte</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <el-tooltip :content="$t('message.new')" placement="top" >
            <base-button type="primary" icon round size="sm" @click="onNew" style="height: 30px;" :disabled="project && project.state === 'ARCHIVED'">
              <i class="fas fa-plus p-0"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip :content="$t('message.copy')" placement="top" >
            <base-button type="primary" icon round size="sm" @click="onCopy" style="height: 30px;" :disabled="project && project.state === 'ARCHIVED'" v-if="this.currentUserRights.includes('task.copy.cancopy') && project_id">
              <i class="fas fa-copy p-0"></i>
            </base-button>
          </el-tooltip>
        </div>
      </div>      
    </base-header>
    <task-list ref="tasks" :show-card-toggle="$route.meta.showCardToggle"></task-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import TaskList from '@/components/Tasks/TaskList'

export default {
  name: 'tasks',
  components: {
    TaskList
  },
  data() {
    const { params } = this.$route;
    return {
      project_id: "id" in params ? parseInt(params.id) : null,
    }
  },
  computed: {
    ...mapGetters([
      'project',
      'currentUserRights'
    ])
  },
  methods: {
    onNew() {
      this.$refs.tasks.onNew()
    },
    onCopy () {
      this.$refs.tasks.onCopyClick()
    }
  }
}
</script>
