<template>
  <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
          <!-- <a href="#" @click.prevent="prev" class="fullcalendar-btn-prev btn btn-sm btn-default">
            <i class="fas fa-angle-left"></i>
          </a>
          <a href="#" @click.prevent="next" class="fullcalendar-btn-next btn btn-sm btn-default">
            <i class="fas fa-angle-right"></i>
          </a> -->
          <!-- <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'dayGridMonth'}"
                       @click="changeView('dayGridMonth')">
            Monat
          </base-button> -->
          <!-- <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'dayGridWeek'}"
                       @click="changeView('dayGridWeek')">
            Woche
          </base-button> -->
          <!-- <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'timeGridDay'}"
                       @click="changeView('timeGridDay')">
            Tag
          </base-button> -->
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- Fullcalendar -->
          <div class="card card-calendar">
            <!-- Card header -->
            <div class="card-header">
              <!-- Title -->
              <h5 class="h3 mb-0">Termine</h5>
            </div>
            <!-- Card body -->
            <div class="card-body p-0 card-calendar-body">
              <gantt-elastic :tasks="tasks" :options="options" :dynamic-style="dynamicStyle" />
            </div>
          </div>
        </div>
      </div>
    </div>
<!-- 
    <modal :show.sync="showEditModal" modal-classes="modal-secondary">
      <form class="edit-event--form" @submit.prevent="editEvent">
        <base-input label="Titel"
                    placeholder="Titel"
                    v-model="model.title"
                    input-classes="form-control-alternative new-event--title">
        </base-input>
        <div class="form-group">
          <label class="form-control-label d-block mb-3">Farbe</label>
          <div class="btn-group btn-group-toggle btn-group-colors event-tag">

            <label v-for="color in eventColors"
                   :key="color"
                   class="btn"
                   :class="[color, {'active focused': model.className === color}]">
              <input v-model="model.className" type="radio" name="event-tag" :value="color" autocomplete="off">
            </label>
          </div>
        </div>
        <base-input label="Beschreibung">
          <textarea v-model="model.description"
                    class="form-control form-control-alternative edit-event--description textarea-autosize"
                    placeholder="Beschreibung">
          </textarea>
          <i class="form-group--bar"></i>
        </base-input>
        <input type="hidden" class="new-event--start"/>
        <input type="hidden" class="new-event--end"/>
      </form>

      <template slot="footer">
        <base-button native-type="submit" type="primary" class="new-event--add" @click="editEvent">Aktualisieren</base-button>
        <base-button type="danger" @click="deleteEvent">Löschen</base-button>
        <base-button type="link" class="ml-auto" @click="showAddModal = false">Schließen</base-button>
      </template>
    </modal> -->
  </div>
</template>
<script>
  import GanttElastic from 'gantt-elastic'
  import dayjs from 'dayjs'


  function getDate(hours) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const timeStamp = new Date(currentYear, currentMonth, currentDay, 0, 0, 0).getTime();
    return new Date(timeStamp + hours * 60 * 60 * 1000).getTime();
  }

  const today = new Date();
  const y = today.getFullYear();
  const m = today.getMonth();
  const d = today.getDate();
  export default {
    name: 'calendar',
    components: {
      GanttElastic
    },
    data() {
      let yearAndMonth = `${y}-${m + 1}`
      return {
        // dynamicStyle: {
        //     'task-list-header-label': {
        //       'font-weight': 'bold'
        //     }
        // },
        tasks: [
          {
            id: 1,
            label: 'DFB-Pokalfinale 2019',
            user: 'Kim Gehrmann',
            start: getDate(-24 * 5),
            duration: 15 * 24 * 60 * 60 * 1000,
            progress: 50,
            type: 'project'
            //collapssed: true,
          },
          {
            id: 2,
            label: 'VB1',
            user: 'Kim Gehrmann',
            parentId: 1,
            start: getDate(-24 * 4),
            duration: 24 * 60 * 60 * 1000,
            progress: 100,
            type: 'milestone',
            collapsed: false,
            style: {
              base: {
                fill: '#1EBC61',
                stroke: '#0EAC51'
              }
              /*'tree-row-bar': {
                fill: '#1EBC61',
                stroke: '#0EAC51'
              },
              'tree-row-bar-polygon': {
                stroke: '#0EAC51'
              }*/
            }
          },
           {
            id: 3,
            label: 'Kick Off Meeting',
            user: 'Kim Gehrmann',
            parentId: 1,
            start: getDate(-24 * 2),
            duration: 24 * 60 * 60 * 1000,
            progress: 100,
            type: 'milestone',
            collapsed: false,
            style: {
              base: {
                fill: '#1EBC61',
                stroke: '#0EAC51'
              }
              /*'tree-row-bar': {
                fill: '#1EBC61',
                stroke: '#0EAC51'
              },
              'tree-row-bar-polygon': {
                stroke: '#0EAC51'
              }*/
            }
          },
          {
            id: 4,
            label: 'Projekt anlegen',
            user: 'Lorena Nickel',
            parentId: 1,
            start: getDate(-24 * 1),
            duration: 2 * 24 * 60 * 60 * 1000,
            progress: 100,
            type: 'task'
          },
          // {
          //   id: 3,
          //   label: 'Courage is being scared to death, but saddling up anyway.',
          //   user:
          //     '<a href="https://www.google.com/search?q=John+Wayne" target="_blank" style="color:#0077c0;">John Wayne</a>',
          //   parentId: 2,
          //   start: getDate(-24 * 3),
          //   duration: 2 * 24 * 60 * 60 * 1000,
          //   progress: 100,
          //   type: 'task'
          // },
          // {
          //   id: 4,
          //   label: 'Put that toy AWAY!',
          //   user:
          //     '<a href="https://www.google.com/search?q=Clark+Kent" target="_blank" style="color:#0077c0;">Clark Kent</a>',
          //   start: getDate(-24 * 2),
          //   duration: 2 * 24 * 60 * 60 * 1000,
          //   progress: 50,
          //   type: 'task',
          //   dependentOn: [3],
      
          // }
        ],
        options: {
          maxRows: 100,
          maxHeight: 300,
          title: {
            label: 'Your project title as html (link or whatever...)',
            html: false
          },
          row: {
            height: 30
          },
          calendar: {
            hour: {
              display: false
            }
          },
          chart: {
            progress: {
              bar: false
            },
            expander: {
              display: true
            }
          },
          taskList: {
            expander: {
              straight: false
            },
            widthFromPercentage: 0,
            columns: [
              {
                id: 1,
                label: 'ID',
                value: 'id',
                width: 40
              },
            
              {
                id: 3,
                label: 'Bearbeiter',
                value: 'user',
                width: 130,
                html: true
              },
            
            ]
          }
          /*locale:{
            name: 'pl', // name String
            weekdays: 'Poniedziałek_Wtorek_Środa_Czwartek_Piątek_Sobota_Niedziela'.split('_'), // weekdays Array
            weekdaysShort: 'Pon_Wto_Śro_Czw_Pią_Sob_Nie'.split('_'), // OPTIONAL, short weekdays Array, use first three letters if not provided
            weekdaysMin: 'Pn_Wt_Śr_Cz_Pt_So_Ni'.split('_'), // OPTIONAL, min weekdays Array, use first two letters if not provided
            months: 'Styczeń_Luty_Marzec_Kwiecień_Maj_Czerwiec_Lipiec_Sierpień_Wrzesień_Październik_Listopad_Grudzień'.split('_'), // months Array
            monthsShort: 'Sty_Lut_Mar_Kwi_Maj_Cze_Lip_Sie_Wrz_Paź_Lis_Gru'.split('_'), // OPTIONAL, short months Array, use first three letters if not provided
            ordinal: n => `${n}`, // ordinal Function (number) => return number + output
            relativeTime: { // relative time format strings, keep %s %d as the same
              future: 'za %s', // e.g. in 2 hours, %s been replaced with 2hours
              past: '%s temu',
              s: 'kilka sekund',
              m: 'minutę',
              mm: '%d minut',
              h: 'godzinę',
              hh: '%d godzin', // e.g. 2 hours, %d been replaced with 2
              d: 'dzień',
              dd: '%d dni',
              M: 'miesiąc',
              MM: '%d miesięcy',
              y: 'rok',
              yy: '%d lat'
            }
        }*/
        },
        monthsLocale: [
          'Januar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember'
        ],
        eventColors: ['bg-info', 'bg-orange', 'bg-red', 'bg-green', 'bg-default', 'bg-blue', 'bg-purple', 'bg-yellow']
      };
    },
    methods: {
     
      onDateClick({ date }) {
        this.showAddModal = true
        this.model.start = date
        this.model.end = date
      },
      onEventClick({ el, event }) {
        this.model = {
          title: event.title,
          className: event.classNames ? event.classNames.join(' '): '',
          start: event.start,
          end: event.end,
          description: 'Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
        }
        this.showEditModal = true
      },
      saveEvent() {
        if (this.model.title) {
          let event = {
            ...this.model,
            allDay: true
          }
          this.events.push(JSON.parse(JSON.stringify(event)))

          this.model = {
            title: '',
            eventColor: 'bg-danger',
            start: '',
            end: ''
          }
        }
        this.showAddModal = false
      },
      editEvent() {
        let index = this.events.findIndex(e => e.title === this.model.title)
        if (index !== -1) {
          this.events.splice(index, 1, this.model)
        }
        this.showEditModal = false
      },
      deleteEvent() {
        let index = this.events.findIndex(e => e.title === this.model.title)
        if (index !== -1) {
          this.events.splice(index, 1)
        }
        this.showEditModal = false
      }
    },
    mounted () {
      // this.calendarApi()
    }
  };
</script>
<style lang="scss">
  @import "~@fullcalendar/core/main.css";
  @import '~@fullcalendar/daygrid/main.css';
  @import '~@fullcalendar/timegrid/main.css';
  @import "~@/assets/sass/core/vendors/fullcalendar";
</style>
